<template>
	<div>
	<a name="top"></a>
		<div class="center"
			:style="[titleFontStyle]"
		>
			Current Postings
		</div>
	<div
		v-for="block in blocks"
		:key="block.id"
	>
		<div
			class=mobileRowCont
			:style="block.bg_color.length>0?'background-color:'+block.bg_color:'background-image: url('+block.background+');'"
		>
			<div class="center">
				<div class=multi-cont-text>
					<div class="job_title">
						Job ID {{ block.id }}, {{block.title}}.&nbsp;
					</div>
					<div class="job_location">
						{{ block.label }}.&nbsp;
					</div>
					<div class="job_details">
						<a 
							:href="'#post_'+block.id"
						>details</a>
					</div>
				</div>
			</div>
		</div>
	</div>
	<br><br>
	<v-divider></v-divider>
	<br>
	<div
		v-for="block in blocks"
		:key="block.id"
	>
		<a
			:name="'post_'+block.id"
		></a>
		<div 
			class="hidden-sm-and-down"
		>
			<div
				class=rowCont
				:style="block.bg_color.length>0?'background-color:'+block.bg_color:'background-image: url('+block.background+');'"
			>
				<div class="center">
					<div
						:style="[titleFontStyle]"
					>
						{{ block.title }}
					</div>
					<div
					>
						Job ID {{ block.id }}
						<i>Posted {{ block.readableDate }}</i><br>
						Location: {{ block.label }} 
					</div>
					<br>
					<div class=multi-cont-text>
						<div
							:style="[textFontStyle]"
						> 
						<span
							v-html="block.text"
						></span>
						</div>
						<br>
						<a 
							:href="'mailto:careers@tblsys.com?subject=Posting '+block.id+', '+block.title" target="_blank"
						>
							Apply
						</a> 
						&nbsp;|&nbsp;
						<a href="#top">Top</a>
					</div>
				</div>
				<div class=center>
					<div class="multi-cont">
					<div
						style="float: left; padding: 6px 6px 6px 0px;"
						v-for="button in block.buttons"
						:key="button.id"
					>
						<v-btn
							depressed
							:href=button.target
						>
							{{ button.title }}
							<v-icon>
								{{ button.icon }}
							</v-icon>
						</v-btn>
					</div>
					</div>
				</div>
			</div>
		</div>

		<v-divider></v-divider>
		<div class=gutter></div>
		</div>

		<!-- Phone View -->
		<div 
			class="hidden-md-and-up"
		>
		</div>
	</div>
</template>

<script>
module.exports = {
	data() {
		return {
			textFontStyleMobile: {
			},
			titleFontStyleMobile: {
			},
			textFontStyle: {
			},
			titleFontStyle: {
			},
			blocks: {},
			link: {
				blur: {
					"bottom": "8px",
					"left": "16px",
					"fontSize": "22px",
					"fontColor": "black"
				},
				focus: { 
					"bottom": "150px",
					"left": "40px",
					"fontSize": "40px",
					"fontColor": "white"
				}
			},
			blockLayout: {
				"img_left_block": {
					imgHolderClass: "left",
					txtHolderClass: "right"
				},
				"img_right_block": {
					imgHolderClass: "right",
					txtHolderClass: "left"
				},
				"two_pic_block": {
					imgHolderClass: "left",
					txtHolderClass: "right"
				},
				"single_pic_block": {
					imgHolderClass: "right",
					txtHolderClass: "left"
				},
				"top_block": {
					imgHolderClass: "left",
					txtHolderClass: "right"
				},
				"side_by_side": {
					imgHolderClass: "left",
					txtHolderClass: "right"
				}
			}
		};
	},
	methods: {
		go(href) {
			this.$router.push({name:href})
		},
		picLinkMO(e) {
			var t = event.target||event.srcElement
			var bg = t.parentElement.children[1]; 
			var text = t.parentElement.children[2]; 
			text.style.bottom=this.link.focus.bottom;
			text.style.left=this.link.focus.left;
			text.style.fontSize=this.link.focus.fontSize;
			text.style.color=this.link.focus.fontColor;
			bg.style.opacity = "50%";
			bg.style.bottom="0px";
			bg.style.left="0px";
			bg.style.backgroundColor="black";
			bg.style.width="100%";
			bg.style.height="100%";
		},
		picLinkML(e) {
			var t = event.target||event.srcElement
			var bg = t.children[1];
			var text = t.children[2]; 
			text.style.bottom=this.link.blur.bottom;
			text.style.left=this.link.blur.left;
			text.style.fontSize=this.link.blur.fontSize;
			text.style.color=this.link.blur.fontColor;
			bg.style.width="";
			bg.style.height="0";
		},
        getReadableDate(ts) {
            console.log(ts);
            var t = new Date(ts*1000);
            //var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
            var options = { year: 'numeric', month: 'long', day: 'numeric' };
            var times = t.toLocaleDateString("en-US", options);
            console.log(times);
            return(times);
        }
	},
	mounted() { 
		this.$http.get("/svc/page"+this.$route.path).then(response => { 
			this.blocks = response.data.pageBlocks;
			this.titleFontStyle = response.data.global.title_font_style;
			this.titleFontStyleMobile = response.data.global.title_font_style_mobile;
			this.textFontStyle = response.data.global.text_font_style;
			this.textFontStyleMobile = response.data.global.text_font_style_mobile;

			var i=0;
			for(i=0; i<this.blocks.length; i++) {
				this.blocks[i].readableDate = this.getReadableDate(this.blocks[i].unix_ts)
			}
		}) 
	}
};
</script>

<style scoped>
.left {
	display: inline-block;
	float: left;
	width: 50%;
	padding-right: 2%;
	padding-left: 2%;
}

.right {
	display: inline-block;
	float: right;
	width: 50%;
	padding-right: 2%;
	padding-left: 2%;
}

.mobileRowCont {
	display: inline-block;
	width: 100%;
}

.rowCont {
	display: inline-block;
	width: 100%;
	background-repeat: repeat-x;
	min-height: 100px;
}

.gutter {
	display:inline-block;
	width: 100%;
	height: 5px;
}

.carousel >>> img {
	object-fit: cover;
	position: absolute;
	width: 100%;
	height: 100%;
	max-height: 540px
}

.carousel >>> video {
	object-fit: cover;
	position: absolute;
	width: 100%;
	height: 100%;
	max-height: 540px
}

.multi-cont {
	display: flex;
	align-items: center;
	justify-content: center;
}

.multi-cont-text {
	text-align: left;
	display: inline-block;
	width: 75%;
}

.multi-cont >>> img {
	display: block;
	max-height: 400px;
	height: 400px;
	width: auto;
	float: left;
	padding: 6px;
}

.multi-cont >>> video {
	display: block;
	max-height: 400px;
	height: 400px;
	width: auto;
	float: left;
	padding: 6px;
}

.link-cont {
	position: relative;
	text-align: center;
	color: black;
}

.link-cont >>> div.linkLayer  {
	position: absolute;
	transition-property: all;
	transition-duration: 0.1s;
	transition-timing-function: linear;
}

.link-cont >>> div.textCont  {
	font-weight: bold;
	font-size: 22px;
	bottom: 8px;
	left: 16px;
	position: absolute;
	transition-property: all;
	transition-duration: 0.25s;
	transition-timing-function: linear;
}

.job_title {
	display: inline-block;
	width: 300px;
	overflow: hidden;
}

.job_location {
	display: inline-block;
	width: 150px;
	overflow: hidden;
}

.job_details {
	display: inline-block;
	width: 150px;
	overflow: hidden;
}

</style>
